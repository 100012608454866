import { Link } from '@chakra-ui/react';
import styled from 'styled-components';

const StyledLink = styled(Link)`
  text-decoration: none;

  &:hover,
  &:active {
    text-decoration: none;
  }
`;

export default props => <StyledLink {...props} />;
