import React, { useEffect, useContext } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { userContext } from '../AppContext';

export default function Auth() {
  const { config } = useContext(userContext);
  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.logged === 'false') {
      navigate('/');
    }

    if (query.get('code'))
      axios
        .get(
          config.url_api_prod +
            '/access-token/' +
            query.get('code') +
            '/' +
            localStorage.email
        )
        .then(res => {
          console.log(res.data);
          localStorage.setItem('user_id', res.data.id);
          localStorage.setItem('access_token', res.data.access_token);
          localStorage.setItem('public_key', res.data.public_key);
          localStorage.setItem('merchant', res.data);
          localStorage.setItem('hash', query.get('code'));
          //clavar unos localstorage y redireccionar
          navigate('/nick-avatar');
        });
  }, []);

  return query.get('code');
}
