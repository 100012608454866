import { motion } from 'framer-motion';
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  HStack,
  InputRightElement,
  Stack,
  Button,
  Heading,
  Text,
  useColorModeValue,
  Link,
  Center,
} from '@chakra-ui/react';
import { useState, useContext, useEffect } from 'react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { ReactComponent as MercadoPagoLogo } from './assets/mercado-pago.svg';
import { HamburgerIcon, CloseIcon, AddIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import { userContext } from '../AppContext';
type ForgotPasswordFormInputs = {
  email: string,
};

export function IntegracionMP(): JSX.Element {
  useEffect(() => {
    if (localStorage.logged === 'false') navigate('/');
  }, []);
  const navigate = useNavigate();
  const { user, handleUserUpdate, config } = useContext(userContext);
  const [url_sitio, setUrl_sitio] = useState('https://modelo.estoy.live/auth');
  //const { url_sitio, setUrl_sitio } = useState('http://modelo.estoy.live/');
  const conectarMercadoPago = () => {
    window.location.href = `https://auth.mercadopago.com/authorization?client_id=2401159577768886&response_type=code&platform_id=mp&state=${(
      Math.random(0, 10) * 10000
    ).toFixed(0)}&redirect_uri=${url_sitio}`;
  };
  return (
    <Box
      minH={'100vh'}
      align={'center'}
      justify={'center'}
      bg={useColorModeValue('brand.250', 'gray.800')}
      p={16}
    >
      <Stack
        spacing={1}
        w={'full'}
        maxW={'md'}
        bg={useColorModeValue('white', 'gray.700')}
        rounded={'xl'}
        boxShadow={'lg'}
        p={6}
        my={1}
        onClick={() => conectarMercadoPago()}
      >
        <Heading lineHeight={1.1} fontSize={{ base: '2xl', md: '3xl' }}>
          <Center> Conecta tu MercadoPago</Center>
        </Heading>
        <Text
          fontSize={{ base: 'sm', sm: 'md' }}
          color={useColorModeValue('gray.800', 'gray.400')}
        >
          <Center>
            <small>Una unica vez y comienza a cobrar tus llamadas!</small>
          </Center>
        </Text>
        <FormControl id="email">
          <Box
            style={{ cursor: 'pointer' }}
            variant={'solid'}
            backgroundColor={'transparent'}
            size={'sm'}
            mr={1}
            p={1}
          >
            <AddIcon />
          </Box>

          <Box
            style={{ cursor: 'pointer' }}
            variant={'solid'}
            backgroundColor={'#f0f0f0'}
            size={'sm'}
            mr={5}
            p={2}
          >
            <MercadoPagoLogo width="180px" />
          </Box>
        </FormControl>
        <Stack spacing={6}></Stack>
      </Stack>
    </Box>
  );
}

export default function Integracion({ isVisible }) {
  const [existe, setExiste] = useState(true);
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <IntegracionMP />
    </motion.div>
  );
}
