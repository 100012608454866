import React, { useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  AlertIcon,
  Alert,
  Box,
  Heading,
  Center,
} from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { FaCopy } from 'react-icons/fa';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useState } from 'react';
export default NiceModal.create(({ link_inv = '', link_creadora = '' }) => {
  const modal = useModal();
  const [copied, setCopied] = useState(false);
  const handleEntrarClick = () => {};
  return (
    <>
      <Modal
        id="infomodal"
        closeOnOverlayClick={false}
        isOpen={modal.visible}
        onClose={() => modal.hide()}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader></ModalHeader>
          <ModalCloseButton />
          <ModalBody p={3}>
            <Box>
              <Box textAlign="center" py={3} px={6}>
                <CheckCircleIcon boxSize={'50px'} color={'green.500'} />
                <Heading as="h2" size="xl" mt={6} mb={2}>
                  A Invitar!
                </Heading>
              </Box>
              <Box pb={3} style={{ cursor: 'pointer' }}>
                {link_inv.indexOf('undefined') !== -1 ? (
                  <Alert status="error">
                    <AlertIcon />
                    <Box paddingLeft={5}>
                      Algo salio mal! porfavor, comienza de nuevo.
                    </Box>
                  </Alert>
                ) : !copied ? (
                  <Alert
                    status="info"
                    alignItems="center"
                    justifyContent="center"
                    textAlign="center"
                  >
                    <FaCopy
                      onClick={() => {
                        navigator.clipboard.writeText(link_inv);
                        setCopied(true);
                        setTimeout(() => {
                          setCopied(false);
                        }, 3000);
                      }}
                    />
                    <Box
                      paddingLeft={5}
                      onClick={() => {
                        navigator.clipboard.writeText(link_inv);
                        setCopied(true);
                        setTimeout(() => {
                          setCopied(false);
                        }, 3000);
                      }}
                    >
                      {link_inv}
                    </Box>
                  </Alert>
                ) : (
                  copied && (
                    <Alert status="success" justifyContent="center">
                      <AlertIcon />
                      <Box>Copiado!</Box>
                    </Alert>
                  )
                )}
              </Box>
              <Text justifyContent="center" textAlign="center">
                Copia tu link y comienza a invitar clientes!
              </Text>
            </Box>
          </ModalBody>
          <ModalFooter>
            {link_inv.indexOf('undefined') !== -1 ? (
              <Button onClick={() => window.location.reload()}>Recargar</Button>
            ) : (
              <Button onClick={() => modal.hide()}>Continuar</Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
});
