import {
  Heading,
  Avatar,
  Box,
  Center,
  Text,
  Stack,
  Button,
  Input,
  Textarea,
} from '@chakra-ui/react';
import NiceModal from '@ebay/nice-modal-react';
import axios from 'axios';

import React, { useRef, useEffect, useState, useContext } from 'react';
import useAxios from './useAxios';
import { useNavigate } from 'react-router-dom';

import { userContext } from '../AppContext';

export default function ProfileScreen() {
  useEffect(() => {
    if (localStorage.logged === 'false') {
      setFrase('nada');
      navigate('/');
    }
  }, []);
  const navigate = useNavigate();
  const { user, handleUserUpdate, config } = useContext(userContext);
  const { response, loading, error } = useAxios({
    method: 'get',
    url: '/modelo/' + localStorage.email,
  });

  const [data, setData] = useState([]);
  const fileSelector = useRef(null);

  const onSelectFile = event => {
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.addEventListener('load', () => {
        NiceModal.show('cropper', {
          props: { image: reader.result },
        }).then(() => {
          setAvatar(user.avatar);
          localStorage.avatar = user.avatar;
        });
      });
    }
  };

  useEffect(() => {
    if (localStorage.logged === 'false') {
      navigate('/');
    }
    if (response !== null) {
      console.log(response);
      setData(response);
      handleUserUpdate(user, 'email', localStorage.email);
      setEmail(localStorage.email);
      handleUserUpdate(user, 'frase', response[0]?.frase);
      setFrase(response[0].frase);
      handleUserUpdate(user, 'nickname', response[0]?.nick);
      setNickname(response[0].nick);
      handleUserUpdate(user, 'avatar', response[0]?.avatar);
      setAvatar(response[0].avatar);
      if (response[0].hash_id === '') localStorage.hash = 'none';
      else localStorage.hash = response[0].hash_id;
    }
  }, [response]);

  const [nickname, setNickname] = useState();
  const [frase, setFrase] = useState();
  const [avatar, setAvatar] = useState();
  const [email, setEmail] = useState();
  const [onLoading, setOnLoading] = useState(false);

  const handleChangeNickname = e => {
    setNickname(e.target.value);
  };
  const triggerFileSelectPopup = () => {
    fileSelector.current.click();
  };

  let handleInputChange = e => {
    let inputValue = e.target.value;
    setFrase(inputValue);
  };

  const handleSubmit = () => {
    setOnLoading(true);
    axios
      .post(config.url_api_dev + '/modelo/save', {
        nick: nickname,
        frase: frase,
        avatar: avatar,
        email: email,
      })
      .then(res => {
        console.log(res.status);
        setOnLoading(false);
        navigate('/pricing');
      });
  };

  return (
    <Center py={6}>
      {loading ? (
        <p>loading...</p>
      ) : (
        <div>
          {error && (
            <div>
              <p>{error.message}</p>
            </div>
          )}

          <Box
            maxW={'320px'}
            w={'100%'}
            bg={'white'}
            boxShadow={'2xl'}
            rounded={'lg'}
            p={6}
            textAlign={'center'}
          >
            <Avatar
              size={'xl'}
              src={avatar}
              alt={'Avatar Alt'}
              mb={4}
              pos={'relative'}
              onClick={triggerFileSelectPopup}
              style={{
                width: '100%',
                height: 'auto',
                position: 'relative',
                top: 0,
                left: 0,
              }}
              _after={{
                content: '""',
                w: 21,
                h: 21,
                bg: 'green.300',
                border: '0px solid white',
                rounded: 'full',
                pos: 'absolute',
                bottom: '3px',
                right: '40px',
              }}
            />

            <Input
              type="file"
              accept="image/*"
              ref={fileSelector}
              onChange={onSelectFile}
              display="none"
            />
            <Heading fontSize={'2xl'} fontFamily={'body'}>
              <Input
                placeholder={nickname ? nickname : 'Gem101'}
                onChange={handleChangeNickname}
              />
            </Heading>
            <Text fontWeight={600} color={'gray.500'} mb={4}>
              @{nickname ? nickname : 'Gem101'}
            </Text>
            <Text textAlign={'center'} color={'gray.700'} px={3}>
              Escribe una frase de bienvenida
            </Text>
            <Textarea
              value={frase}
              onChange={handleInputChange}
              placeholder="Here is a sample placeholder"
              size="sm"
            />

            <Stack
              align={'center'}
              justify={'center'}
              direction={'row'}
              mt={6}
            ></Stack>

            <Stack mt={8} direction={'row'} spacing={4}>
              <Button
                flex={1}
                isLoading={onLoading}
                onClick={handleSubmit}
                fontSize={'sm'}
                bg={'brand.350'}
                color={'black'}
                boxShadow={
                  '0px 1px 25px -5px rgb(66 153 225 / 48%), 0 10px 10px -5px rgb(66 153 225 / 43%)'
                }
                _hover={{
                  bg: 'brand.600',
                }}
                _focus={{
                  bg: 'brand.600',
                }}
              >
                Siguiente
              </Button>
            </Stack>
          </Box>
          <div>{data && <p>{data.id}</p>}</div>
        </div>
      )}
    </Center>
  );
}
