import React, { ReactNode, useContext, useEffect } from 'react';
import {
  Box,
  Flex,
  Avatar,
  HStack,
  Link,
  IconButton,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useDisclosure,
  useColorModeValue,
  Stack,
  Center,
  Circle,
} from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import { userContext } from '../AppContext';
import { RiLiveFill } from 'react-icons/ri';

const Links = ['Dashboard', 'Projects', 'Team'];

export default function NavBar() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { user, config, setConfig, handleActiveNavLink, isLogged, setIsLoged } =
    useContext(userContext);
  const color = useColorModeValue('gray.200', 'gray.700');
  useEffect(() => {}, [user]);
  return (
    <Box bg={useColorModeValue('brand.100', 'gray.900')} px={4}>
      <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
        <HStack spacing={8} alignItems={'center'}>
          <HStack as={'nav'} spacing={[0, 3, 5]}>
            <NavLink
              px={2}
              py={1}
              rounded={'md'}
              _hover={{
                textDecoration: 'none',
                bg: useColorModeValue('gray.200', 'gray.700'),
              }}
              to={'/'}
            >
              <Circle
                w="40px"
                h="40px"
                bg={config.paso1 ? 'black' : 'brand.350'}
                color={config.paso1 ? 'white' : 'black'}
                onClick={() => handleActiveNavLink(1)}
              >
                <Box as="span" fontWeight="bold" fontSize="lg">
                  1
                </Box>
              </Circle>
            </NavLink>

            <NavLink
              px={2}
              py={1}
              rounded={'md'}
              _hover={{
                textDecoration: 'none',
                bg: { color },
              }}
              to={'/nick-avatar'}
            >
              <Circle
                w="40px"
                h="40px"
                bg={config.paso2 ? 'black' : 'brand.350'}
                color={config.paso2 ? 'white' : 'black'}
                onClick={() => handleActiveNavLink(2)}
              >
                <Box as="span" fontWeight="bold" fontSize="lg">
                  2
                </Box>
              </Circle>
            </NavLink>

            <NavLink
              px={2}
              py={1}
              rounded={'md'}
              _hover={{
                textDecoration: 'none',
                bg: { color },
              }}
              to={'/pricing'}
            >
              <Circle
                w="40px"
                h="40px"
                bg={config.paso3 ? 'black' : 'brand.350'}
                color={config.paso3 ? 'white' : 'black'}
                onClick={() => handleActiveNavLink(3)}
              >
                <Box as="span" fontWeight="bold" fontSize="lg">
                  3
                </Box>
              </Circle>
            </NavLink>
            <NavLink
              px={2}
              py={1}
              rounded={'md'}
              _hover={{
                textDecoration: 'none',
                bg: { color },
              }}
              to={'/auth'}
            >
              <Circle
                w="40px"
                h="40px"
                bg={config.paso4 ? 'black' : 'brand.350'}
                color={config.paso4 ? 'white' : 'black'}
                onClick={() => handleActiveNavLink(4)}
              >
                <Box as="span" fontWeight="bold" fontSize="lg">
                  <RiLiveFill />
                </Box>
              </Circle>
            </NavLink>
          </HStack>
        </HStack>
        {!localStorage.avatar ? null : (
          <Flex alignItems={'center'}>
            <Menu>
              <MenuButton
                as={Button}
                rounded={'full'}
                variant={'link'}
                cursor={'pointer'}
                minW={0}
              >
                <Avatar size={'sm'} src={localStorage.avatar} />
              </MenuButton>
              <MenuList>
                <MenuItem>Link 1</MenuItem>
                <MenuItem>Link 2</MenuItem>
                <MenuDivider />
                <MenuItem>Link 3</MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        )}
      </Flex>

      {isOpen ? (
        <Box pb={4} display={{ md: 'none' }}>
          <Stack as={'nav'} spacing={4}>
            {Links.map(link => (
              <NavLink to={'/' + link} key={link}>
                {link}
              </NavLink>
            ))}
          </Stack>
        </Box>
      ) : null}
    </Box>
  );
}
