import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import InicioLoginRegister from './InicioLoginRegister';
import Integracion from './Integracion';
import Pricing from './Pricing';
import Auth from './Auth';
import NickAvatarFrase from './NickAvatarFrase';
import ProfileScreen from './ProfileScreen';

import { AnimatePresence } from 'framer-motion';

export default function AnimatedRouters() {
  const location = useLocation();
  return (
    <AnimatePresence>
      <Routes location={location} key={location.path}>
        <Route path="/" element={<InicioLoginRegister />} />
        <Route path="/mercadopago" element={<Integracion />} />
        <Route path="/nick-avatar" element={<ProfileScreen />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/auth" element={<Auth />} />
      </Routes>
    </AnimatePresence>
  );
}
