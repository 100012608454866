import { ColorModeScript } from '@chakra-ui/react';
import React, { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import { AppContextProvider } from './AppContext';

import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { ColorModeSwitcher } from './ColorModeSwitcher';

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);
const theme = extendTheme({
  colors: {
    mercadopago: { 100: '#1c3c8e', 200: '#00b0ef' },
    brand: {
      100: '#f6abb6',
      300: '#ebbeff',
      350: '#eec9d2',
      600: '#e3d2ff',
      900: '#fdc8ff',
      200: '#f6abb6',
      250: '#eee3e7 ',
    },
  },
});

root.render(
  <StrictMode>
    <AppContextProvider>
      <ChakraProvider theme={theme}>
        <ColorModeScript />
        <App />
      </ChakraProvider>
    </AppContextProvider>
  </StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
