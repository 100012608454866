import React, { useContext, useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { FaCheckCircle } from 'react-icons/fa';

import {
  Box,
  Center,
  Stack,
  HStack,
  Heading,
  Text,
  VStack,
  useColorModeValue,
  List,
  ListItem,
  ListIcon,
  Button,
  Link,
  Input,
  Editable,
  EditableInput,
  EditableTextarea,
  EditablePreview,
  Tooltip,
  Divider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  ButtonGroup,
  useEditableControls,
  IconButton,
  Flex,
  Image,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import useAxios from './useAxios';
import { useNavigate } from 'react-router-dom';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import axios from 'axios';
import { userContext } from '../AppContext';

function PriceWrapper({ children }: { children: ReactNode }) {
  return (
    <Box
      mb={4}
      shadow="base"
      borderWidth="1px"
      alignSelf={{ base: 'center', lg: 'flex-start' }}
      borderColor={useColorModeValue('gray.200', 'gray.500')}
      borderRadius={'xl'}
    >
      {children}
    </Box>
  );
}

export default function Pricing() {
  const { response, loading, error } = useAxios({
    method: 'get',
    url: '/pricing/' + localStorage.hash,
  });
  const [data, setData] = useState([]);

  const [total10, setTotal10] = useState(200);
  const [total30, setTotal30] = useState(500);
  const [total60, setTotal60] = useState(1000);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const [onLoading, setOnLoading] = useState(false);
  const navigate = useNavigate();
  const { user, handleActiveNavLink, handleUserUpdate, config } =
    useContext(userContext);
  useEffect(() => {
    if (localStorage.logged === 'false') navigate('/');
    handleActiveNavLink(3);
  }, []);

  const createHash = () => {
    const hash = Array.from(Array(6), () =>
      Math.floor(Math.random() * 36).toString(36)
    ).join('');
    handleUserUpdate(user, 'hash', hash);
    localStorage.setItem('hash', hash);
    return hash;
  };

  useEffect(() => {
    if (response !== null && response !== 'no hash') {
      console.log(response);
      if (response[0]?.pricing) {
        const pricing_string = response[0]?.pricing;
        const pricing_keypairs = pricing_string.split(':');
        var arr_key_pair = [];
        pricing_keypairs.map((item, i) => {
          console.log('esto', item);
          arr_key_pair[i] = item.split('|');
          console.log(arr_key_pair[i][0]);
        });
        setTotal10(arr_key_pair[0][0]);
        setTotal30(arr_key_pair[1][0]);
        setTotal60(arr_key_pair[2][0]);
      }
    }
  }, [response]);

  const showLinksModal = (e, i) => {
    NiceModal.show('infomodal', { link_esp: e, link_inv: i }).then(
      setIsSubmitted(false)
    );
  };
  const handleSubmit = () => {
    setOnLoading(true);

    var hash = createHash();
    axios
      .post(config.url_api_dev + '/pricing/save', {
        hash: hash,
        pricing: `${total10}|10:${total30}|30:${total60}|60`,
      })
      .then(res => {
        axios.post(config.url_api_dev + '/newhash', {
          hash: hash,
          email: localStorage.email,
        });
        console.log(res.status);
        setOnLoading(false);
        showLinksModal('', 'https://estoy.live/' + hash);
      });
  };

  const coso = useColorModeValue('red.300', 'red.700');
  const coso2 = useColorModeValue('gray.900', 'gray.300');
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Stack
        direction={{ base: 'column', md: 'row' }}
        textAlign="center"
        justify="center"
        spacing={{ base: 4, lg: 10 }}
        py={10}
      >
        {loading ? (
          <p>loading...</p>
        ) : (
          <div>
            {error && (
              <div>
                <p>{error.message}</p>
              </div>
            )}
            <VStack>
              <Box>
                <Box py={5} maxWidth={'340px'}>
                  <Alert status="info">
                    <AlertIcon />
                    <Text fontSize={'1xl'} textAlign={'left'}>
                      <Text as={'span'} fontWeight={700}>
                        Ultimo paso!
                      </Text>{' '}
                      Establece tus precios a cobrar. Presiona sobre los precios
                      para cambiarlos.
                    </Text>
                  </Alert>
                </Box>
                <PriceWrapper>
                  <Box py={4} px={12}>
                    <Text fontWeight="500" fontSize="2xl">
                      10 minutos
                    </Text>
                    <HStack justifyContent="center">
                      <Text fontSize="3xl" fontWeight="600">
                        $
                      </Text>

                      <Input
                        fontSize="3xl"
                        fontWeight="900"
                        value={!loading ? total10 : '200'}
                        onChange={e => setTotal10(e.target.value)}
                        style={{ minWidth: '200px', maxWidth: '210px' }}
                      />
                    </HStack>
                    <Text fontWeight="300" fontSize="1xl">
                      El usuario abonara:{' '}
                      <Tooltip label="Ten en cuenta que se agregara un 7% de comision para MercadoPago y un 3% para estoy.live + un fijo de 20 pesos">
                        {(total10 * 1.1 + 20).toFixed(0)}
                      </Tooltip>
                    </Text>
                  </Box>
                  <VStack bg={'gray.50'} py={4} borderBottomRadius={'xl'}>
                    <List spacing={3} textAlign="start" px={12}>
                      <ListItem>
                        <ListIcon as={FaCheckCircle} color="green.500" />
                        Accesso al chat
                      </ListItem>
                    </List>
                    <Box w="80%" pt={7}>
                      <Button
                        disabled
                        w="full"
                        colorScheme="red"
                        variant="outline"
                      >
                        Elegir
                      </Button>
                    </Box>
                  </VStack>
                </PriceWrapper>

                <PriceWrapper>
                  <Box position="relative">
                    <Box
                      position="absolute"
                      top="-16px"
                      left="50%"
                      style={{ transform: 'translate(-50%)' }}
                    >
                      <Text
                        textTransform="uppercase"
                        bg={coso}
                        px={3}
                        py={1}
                        color={coso2}
                        fontSize="sm"
                        fontWeight="600"
                        rounded="xl"
                      >
                        Mas Popular
                      </Text>
                    </Box>
                    <Box py={4} px={12}>
                      <Text fontWeight="500" fontSize="2xl">
                        30 minutos
                      </Text>
                      <HStack justifyContent="center">
                        <Text fontSize="3xl" fontWeight="600">
                          $
                        </Text>
                        <Input
                          fontSize="3xl"
                          fontWeight="900"
                          value={!loading ? total30 : '500'}
                          onChange={e => setTotal30(e.target.value)}
                          style={{ minWidth: '200px', maxWidth: '210px' }}
                        />
                      </HStack>
                      <Text fontWeight="300" fontSize="1xl">
                        El usuario abonara:
                        <Tooltip label="Ten en cuenta que se agregara un 7% de comision para MercadoPago y un 3% para estoy.live + un fijo de 30 pesos">
                          {(total30 * 1.1 + 30).toFixed(0)}
                        </Tooltip>
                      </Text>
                    </Box>
                    <VStack bg={'gray.50'} py={4} borderBottomRadius={'xl'}>
                      <List spacing={3} textAlign="start" px={12}>
                        <ListItem>
                          <ListIcon as={FaCheckCircle} color="green.500" />
                          Accesso inmediato al chat
                        </ListItem>
                      </List>
                      <Box w="80%" pt={7}>
                        <Button disabled w="full" colorScheme="red">
                          Elegir
                        </Button>
                      </Box>
                    </VStack>
                  </Box>
                </PriceWrapper>
                <PriceWrapper>
                  <Box py={4} px={12}>
                    <Text fontWeight="500" fontSize="2xl">
                      60 minutos
                    </Text>
                    <HStack justifyContent="center">
                      <Text fontSize="3xl" fontWeight="600">
                        $
                      </Text>

                      <Input
                        fontSize="3xl"
                        fontWeight="900"
                        value={!loading ? total60 : '1000'}
                        onChange={e => setTotal60(e.target.value)}
                        style={{ minWidth: '200px', maxWidth: '210px' }}
                      />
                    </HStack>
                    <Text fontWeight="300" fontSize="1xl">
                      El usuario abonara:{' '}
                      <Tooltip label="Ten en cuenta que se agregara un 7% de comision para MercadoPago y un 3% para estoy.live + un fijo de 40 pesos">
                        {(total60 * 1.1 + 40).toFixed(0)}
                      </Tooltip>
                    </Text>
                  </Box>
                  <VStack bg={'gray.50'} py={4} borderBottomRadius={'xl'}>
                    <List spacing={3} textAlign="start" px={12}>
                      <ListItem>
                        <ListIcon as={FaCheckCircle} color="green.500" />
                        Accesso inmediato al chat
                      </ListItem>
                    </List>
                    <Box w="80%" pt={7}>
                      <Button
                        disabled
                        w="full"
                        colorScheme="red"
                        variant="outline"
                      >
                        Elegir
                      </Button>
                    </Box>
                  </VStack>
                </PriceWrapper>
              </Box>
              <Box w="100%">
                <Center>
                  <Button
                    w="50%"
                    colorScheme="red"
                    isLoading={isSubmitted}
                    loadingText="Enviando"
                    onClick={() => handleSubmit()}
                    //    onClick={() => handleGenerarLink()}
                  >
                    Generar Link de invitacion
                  </Button>
                </Center>
              </Box>
            </VStack>
            <div>{data && <p>{data.id}</p>}</div>
          </div>
        )}
      </Stack>
    </motion.div>
  );
}
