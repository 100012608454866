import React, { ReactNode } from 'react';
import {
  Box,
  Flex,
  Avatar,
  HStack,
  Link,
  IconButton,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useDisclosure,
  useColorModeValue,
  Stack,
} from '@chakra-ui/react';
import AnimatedRouters from './components/AnimatedRouters';
import { BrowserRouter, NavLink } from 'react-router-dom';
import { HamburgerIcon, CloseIcon, AddIcon } from '@chakra-ui/icons';
import {
  ChakraProvider,
  Text,
  VStack,
  Code,
  Grid,
  theme,
} from '@chakra-ui/react';
import { ColorModeSwitcher } from './ColorModeSwitcher';
import { Logo } from './Logo';
import NavBar from './components/NavBar';
import { useEffect, useContext } from 'react';
import { userContext } from './AppContext';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import ModalCropper from './components/ModalCropper';
import InfoModal from './components/InfoModal';

NiceModal.register('cropper', ModalCropper);
NiceModal.register('infomodal', InfoModal);

export default function App() {
  const { user } = useContext(userContext);

  useEffect(() => {
    localStorage.logged = false;
    /* var currentUser = {};
    if (localStorage.getItem('user_id')) {
      currentUser = { user_id: localStorage.getItem('user_id') };
    }
    if (localStorage.getItem('nickname')) {
      currentUser = {
        ...currentUser,
        nickname: localStorage.getItem('nickname'),
      };
    }
    if (localStorage.getItem('hash')) {
      currentUser = { ...currentUser, hash: localStorage.getItem('hash') };
    }*/
  }, []);
  return (
    <>
      <NiceModal.Provider>
        <BrowserRouter>
          <NavBar />
          <AnimatedRouters />
        </BrowserRouter>
      </NiceModal.Provider>
    </>
  );
}
